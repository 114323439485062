import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Container, Nav, Navbar, Row } from "react-bootstrap";

import { isLaurence, logout, verificaPermissaoCliente, verificaRota } from "../../service/auth";
import Botao from "../../components/Botao";
import ItemMenu from "./sidebar/ItemMenu";
import ItemDropdown from "./sidebar/ItemDropdown";
import { dadosToken } from "../../components/Util";
import ModalPadrao from "../../components/ModalPadrao";
import Atalho from "../home/containers/Atalho";
import PontoColaborador from "../painel-usuario/ponto/PontoColaborador";
import GerarAssinatura from "../painel-usuario/assinatura/GerarAssinatura";
import Icone from "../../components/Icone";
import CadastraLogo from "../empresa/CadastraLogo";
import {
  urlAmbientes,
  urlAtividades,
  urlAtividadesEspeciais,
  urlCas,
  urlDanos,
  urlEfeitosSaude,
  urlElementos,
  urlEpcs,
  urlEpis,
  urlFerramentas,
  urlFiltrosMascaras,
  urlFrasesPerigo,
  urlFuncionariosClientes,
  urlFuncoesClientes,
  urlJornadasTrabalho,
  urlLinach,
  urlMedidasOrganizacionais,
  urlMetodosAnalise,
  urlPerigos,
  urlProdutosQuimicos,
  urlChecklistPerguntas,
  urlFabricanteQuimico,
  urlBaseDadosErgonomia,
  urlBaseDadosAcidente,
  urlBaseDadosFormasRisco,
  urlBaseDadosObjetivosMetas,
  urlBaseDadosRiscosLocal,
  // urlEquipamentosProcessos,
  // urlRequisitosAplicaveis,
  // urlServicos,
} from "../../routes/UrlBaseDados";
import {
  urlColaboradores,
  urlUsuarios,
  urlVeiculos,
  // urlMeuUsuario,
  // urlListaCliente,
} from "../../routes/UrlAdministrativo";
import SpanBadge from "../../components/SpanBadge";

export const urlOperacional =
  dadosToken() && dadosToken().usuario_operacional
    ? `${process.env.REACT_APP_ERGOSEG_URL}/operacional/index.php/Logar/${dadosToken().usuario_operacional.usuario_id}/${
        dadosToken().usuario_operacional.login
      }/${dadosToken().usuario_operacional.tipo}/${dadosToken().usuario_operacional.usuario}`
    : `${process.env.REACT_APP_ERGOSEG_URL}/operacional/index.php/Login`;

const Header = ({ backTo }) => {
  const [modalAdm, setModalAdm] = useState(false);
  const [modalCadastraLogo, setModalCadastraLogo] = useState(false);
  const [modalPonto, setModalPonto] = useState(false);
  const [modalAssinatura, setModalAssinatura] = useState(false);
  const [modalGro, setModalGro] = useState(false);

  var user = `id_colaborador=${dadosToken().id_colaborador}`;
  let isAdm = dadosToken().tipo === "1";
  let isCliente = dadosToken().tipo === "2";

  const verificaPermissoes = isLaurence() || ["117", "118", "119"].includes(dadosToken().funcao_id);

  let itensEmpresa = [
    {
      icone: "cloud-upload-alt",
      conteudo: "Cadastrar logos",
      modal: () => setModalCadastraLogo(true),
    },
  ];

  if (verificaRota("administrativo")) {
    itensEmpresa.push({
      icone: "business-time",
      conteudo: "Administrativo",
      modal: () => setModalAdm(true),
    });
  }

  let itensComercial = [
    {
      alvo: `${process.env.REACT_APP_ERGOSEG_URL}/comercial/login.php?${user}&endereco=comercial_base_dados.php`,
      icone: "database",
      conteudo: "Base de dados",
      external: true,
    },
    {
      alvo: `${process.env.REACT_APP_ERGOSEG_URL}/comercial/login.php?${user}&endereco=orcamento.php`,
      // alvo: `/comercial/orcamentos/orcamento`,
      icone: "calendar-plus-o",
      conteudo: "Orçamento",
      external: true,
    },
    {
      alvo: `${process.env.REACT_APP_ERGOSEG_URL}/comercial/login.php?${user}&endereco=Operacional`,
      icone: "calendar",
      conteudo: "Agendamento",
      external: true,
    },
    {
      alvo: "/comercial/clientes",
      icone: "user-friends",
      conteudo: "Clientes",
      external: false,
    },
    {
      alvo: "/comercial/Estabelecimentos",
      icone: "building",
      conteudo: "Estabelecimentos",
      external: false,
    },
  ];

  if (verificaPermissoes) {
    itensComercial.push({
      alvo: "/comercial/Informativos",
      icone: "scroll",
      conteudo: "Informativos",
      external: false,
    });
  }

  let itensFinanceiro = [
    {
      alvo: `${process.env.REACT_APP_ERGOSEG_URL}/comercial/login.php?${user}&endereco=Operacional/index.php/faturamento_controller`,
      icone: "database",
      conteudo: "Base de dados",
      external: true,
    },
    {
      alvo: `${process.env.REACT_APP_ERGOSEG_URL}/comercial/login.php?${user}&endereco=AngularJS_v2/public/#!/financeiro/despesas`,
      icone: "television",
      conteudo: "Depreciação",
      external: true,
    },
    {
      alvo: `${process.env.REACT_APP_ERGOSEG_URL}/comercial/login.php?${user}&endereco=AngularJS_v2/public/#!/financeiro/outrasReceitas`,
      icone: "money",
      conteudo: "Outras receitas",
      external: true,
    },
    {
      alvo: `${process.env.REACT_APP_ERGOSEG_URL}/comercial/login.php?${user}&endereco=AngularJS_v2/public/#!/financeiro/notaFiscal`,
      icone: "sticky-note",
      conteudo: "Nota fiscal",
      external: true,
    },
    {
      alvo: "/comercial/previsoes-entrega",
      icone: "list-alt",
      conteudo: "Previsão de entrega",
      external: true,
    },
  ];

  if (
    dadosToken().permissoes.find((item) => {
      return item === "registroPonto";
    })
  ) {
    itensFinanceiro.push({
      alvo: "https://www.ergoseg.com.br/comercial/AngularJS_v2/public/#!/registrosPonto",
      icone: "clock-o",
      conteudo: "Registro Ponto",
      external: true,
    });
  }

  let itensGro = [];

  if (verificaRota("base-dados")) {
    itensGro.push({
      icone: "database",
      conteudo: "Base de dados",
      modal: () => setModalGro(true),
    });
  }

  if (verificaRota("painel") || verificaRota("ppra")) {
    itensGro.push({
      alvo: "/gro/seleciona-cliente",
      icone: "user-friends",
      conteudo: "Clientes",
    });
  }

  if (dadosToken().usuario_operacional) {
    itensGro.push({
      alvo: urlOperacional,
      icone: "leaf",
      conteudo: "Avaliação ambiental",
      external: true,
    });
  }

  if (verificaRota("ergonomia")) {
    itensGro.push({
      alvo: process.env.REACT_APP_ERGOSEG_URL + "/ergonomia2/index.php",
      icone: "male",
      conteudo: "Ergonomia",
      external: true,
    });
  }

  itensGro.push({
    alvo: "/gro/relatorios#1",
    icone: "file-pdf",
    conteudo: "Relatórios",
  });

  return (
    <>
      <Navbar bg="cinza" className="p-0" expand="lg xl">
        <Navbar.Brand className="bg-white shadow rounded-end d-flex align-items-center">
          <Link to={isCliente ? verificaPermissaoCliente() : "/"}>
            <img
              className="mx-3"
              src={
                process.env.REACT_APP_SEGURE_URL + (isCliente ? `uploads/clientes/cli_${dadosToken().cliente_id}.jpg` : "public/logo/logoFundo.jpg")
              }
              width="75"
              alt="Logo"
            />
          </Link>
          {backTo && (
            <Container fluid="xl">
              <Link to={backTo}>
                <Botao className="text-info" icone="arrow-circle-left" title="Voltar" cor="white" tamanhoIcone="24px" />
              </Link>
            </Container>
          )}
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            {isAdm && (
              <>
                <ItemDropdown conteudo="Empresa" icone="building" itens={itensEmpresa} />
                {verificaRota("comercial") && (
                  <>
                    <ItemDropdown conteudo="Comercial" icone="dollar" itens={itensComercial} />

                    <ItemDropdown conteudo="Financeiro" icone="money" itens={itensFinanceiro} />
                  </>
                )}
              </>
            )}
            {isCliente ? (
              <>
                <ItemMenu alvo={verificaPermissaoCliente()} conteudo="Home" icone="home" />
                {verificaRota("painel") && <ItemMenu alvo={`/gro/${dadosToken().cliente_id}/painel`} conteudo="GRO" icone="cog" />}
              </>
            ) : (
              <>
                <ItemDropdown conteudo="GRO" icone="cog" itens={itensGro} />
                <ItemDropdown
                  conteudo="Painel do usuário"
                  icone="list"
                  itens={[
                    {
                      icone: "clock-o",
                      conteudo: "Ponto",
                      modal: () => setModalPonto(true),
                    },
                    {
                      alvo: `${process.env.REACT_APP_ERGOSEG_URL}/comercial/AngularJS/folha_de_rosto/#/home/${dadosToken().id_colaborador}`,
                      icone: "file",
                      conteudo: "Folha de rosto",
                      external: true,
                    },
                    {
                      icone: "file-signature",
                      conteudo: "Gerar assinatura",
                      modal: () => setModalAssinatura(true),
                    },
                  ]}
                />
                {verificaRota("assessoria") && <ItemMenu alvo="https://assessoria.tegis.com.br/" conteudo="SGI" icone="certificate" target />}
                <ItemMenu alvo={"/suporte/ticket"} conteudo="Suporte" icone="bullhorn" target />
              </>
            )}
          </Nav>

          <ItemMenu
            icone="user"
            target
            title="Perfil do Usuário"
            // alvo={`/perfil-ponto/perfil/${dadosToken().id_colaborador}`}
            alvo={`/painel/perfil/${dadosToken().id_colaborador}`}
            tamanhoIcone="20px"
          />

          <Link className="mr-4 justify-content-end" to="/login">
            <Botao className="text-info" icone="sign-out" title="Sair" cor="cinza" onClick={() => logout()} tamanhoIcone="24px" />
          </Link>
        </Navbar.Collapse>
      </Navbar>
      {modalAdm && <ModalAdm show={modalAdm} setShow={setModalAdm} user={user} />}
      {modalCadastraLogo && <CadastraLogo show={modalCadastraLogo} setShow={setModalCadastraLogo} />}
      {modalPonto && <ModalPonto show={modalPonto} setShow={setModalPonto} />}
      {modalAssinatura && <GerarAssinatura show={modalAssinatura} setShow={setModalAssinatura} />}
      {modalGro && <ModalGro show={modalGro} setShow={setModalGro} />}
    </>
  );
};

const ModalAdm = ({ show, setShow, user }) => {
  return (
    <ModalPadrao show={show} setShow={setShow} tamanho="md" titulo="Administrativo" modalCentralizada animation>
      <Row xs={12} md={8} lg={12}>
        <Atalho alvo={urlVeiculos} titulo="Veiculos" icone="car" col noExternal={true} />
        <Atalho
          alvo={`${process.env.REACT_APP_ERGOSEG_URL}/comercial/login.php?${user}&endereco=cliente.php`}
          titulo="Clientes"
          icone="street-view"
          col
        />
        <Atalho alvo={urlColaboradores} titulo="Colaboradores" icone="users" col={{ lg: "6" }} noExternal={true} />
        <Atalho
          alvo={`${process.env.REACT_APP_ERGOSEG_URL}/comercial/login.php?${user}&endereco=AngularJS_v2/public/#!/fornecedor`}
          titulo="Fornecedores"
          icone="truck"
          col={{ lg: "6" }}
        />
        <Atalho alvo={urlUsuarios} titulo="Usuários" icone="user" col={{ lg: "6" }} noExternal={true} />
        {/* <Atalho
          alvo={urlListaCliente}
          titulo="Lista Clientes"
          icone="street-view"
          col={{ lg: "6" }}
          noExternal={true}
        /> */}
      </Row>
    </ModalPadrao>
  );
};

const ModalGro = ({ show, setShow }) => {
  return (
    <ModalPadrao
      show={show}
      setShow={setShow}
      tamanho="xl"
      tituloSpan={
        <>
          <Icone icone="database" /> Bases de dados
        </>
      }
      corTitulo="secondary"
      modalCentralizada
      animation
    >
      <div className="row">
        <div className="col-8 me-4">
          <h4>
            <SpanBadge texto="Geral" />
          </h4>
          <div className="row">
            <Atalho alvo={urlCas} titulo="CA's" icone="subscript" col={{ md: 6, lg: 4 }} />
            <Atalho alvo={urlDanos} titulo="Danos" icone="exclamation-triangle" col={{ md: 6, lg: 4 }} />
            <Atalho alvo={urlEfeitosSaude} titulo="Efeitos Saúde" icone="notes-medical" col={{ md: 6, lg: 4 }} />
            <Atalho alvo={urlElementos} titulo="Elementos" icone="flask" col={{ md: 6, lg: 4 }} />
            <Atalho alvo={urlEpcs} titulo="EPC" icone="user-shield" col={{ md: 6, lg: 4 }} />
            <Atalho alvo={urlEpis} titulo="EPI" icone="headphones" col={{ md: 6, lg: 4 }} />
            <Atalho alvo={urlFiltrosMascaras} titulo="Filtros máscaras" icone="head-side-mask" col={{ md: 6, lg: 4 }} />
            <Atalho alvo={urlFrasesPerigo} titulo="Frases de perigo" icone="scroll" col={{ md: 6, lg: 4 }} />
            <Atalho target alvo={urlLinach} titulo="LINACH" icone="heartbeat" col={{ md: 6, lg: 4 }} />
            <Atalho target alvo={urlMetodosAnalise} titulo="Método de analise" icone="sitemap" col={{ md: 6, lg: 4 }} />
            <Atalho alvo={urlPerigos} titulo="Perigos" icone="skull-crossbones" col={{ md: 6, lg: 4 }} />
            <Atalho target alvo={urlProdutosQuimicos} titulo="Produtos químicos" icone="radiation" col={{ md: 6, lg: 4 }} />
            <Atalho target alvo={urlFabricanteQuimico} titulo="Fabricante Químico" icone="industry" col={{ md: 6, lg: 4 }} />
            <Atalho target alvo={urlChecklistPerguntas} titulo="Checklist de NR's" icone="clipboard-list" col={{ md: 6, lg: 4 }} />
            <Atalho target alvo={urlBaseDadosFormasRisco + "#1"} titulo="Formas de Risco" icone="scroll" col={{ md: 6, lg: 4 }} />

            <Atalho target alvo={urlBaseDadosErgonomia + "#1"} titulo="Ergonomia" icone="street-view" col={{ md: 6, lg: 4 }} />

            <Atalho target alvo={urlBaseDadosAcidente + "#1"} titulo="Acidente" icone="car-crash" col={{ md: 6, lg: 4 }} />

            <Atalho target alvo={urlMedidasOrganizacionais} titulo="Medidas organizacionais do trabalhos" icone="shield" col={{ md: 6, lg: 4 }} />
            <Atalho target alvo={urlBaseDadosObjetivosMetas + "#1"} titulo="Objetivos Metas e Resultados" icone="archive" col={{ md: 6, lg: 4 }} />
            <Atalho target alvo={urlBaseDadosRiscosLocal + "#1"} titulo="Riscos (Local)" icone="scroll" col={{ md: 6, lg: 4 }} />
          </div>
        </div>
        <div className="col">
          <h4>
            <SpanBadge texto="Cliente" cor="cinza" />
          </h4>
          <div className="row">
            <Atalho alvo={urlAmbientes} titulo="Ambientes" icone="map-marker" col={{ lg: 12 }} bg="cinza" border="border border-dark " />
            <Atalho alvo={urlAtividades} titulo="Atividades" icone="tasks" col={{ lg: 12 }} bg="cinza" border="border border-dark " />
            <Atalho
              alvo={urlAtividadesEspeciais}
              titulo="Atividades Especiais"
              icone="star"
              col={{ lg: 12 }}
              bg="cinza"
              border="border border-dark "
            />
            <Atalho alvo={urlFerramentas} titulo="Ferramentas" icone="tools" col={{ lg: 12 }} bg="cinza" border="border border-dark " />
            <Atalho
              alvo={urlFuncionariosClientes}
              titulo="Funcionários clientes"
              icone="users"
              col={{ lg: 12 }}
              bg="cinza"
              border="border border-dark "
            />
            <Atalho
              target
              alvo={urlFuncoesClientes}
              titulo="Funções clientes"
              icone="users-cog"
              col={{ lg: 12 }}
              bg="cinza"
              border="border border-dark "
            />
            <Atalho
              target
              alvo={urlJornadasTrabalho}
              icone="id-badge"
              titulo="Jornada de trabalho"
              col={{ lg: 12 }}
              bg="cinza"
              border="border border-dark "
            />
          </div>
        </div>
      </div>
    </ModalPadrao>
  );
};

const ModalPonto = ({ show, setShow }) => {
  return (
    <ModalPadrao show={show} setShow={setShow} tamanho="sm" titulo="Ponto" modalCentralizada animation>
      <PontoColaborador />
    </ModalPadrao>
  );
};

export default Header;
