import React from "react";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import Icone from "./Icone";

const Botao = ({
  type = "button",
  icone,
  cor = "primary",
  conteudo,
  className = "",
  onClick,
  title = "",
  disabled,
  alvo,
  isLoading,
  outline = false,
  block,
  tamanhoIcone,
  onInput,
  noBlank = null,
  style,
  router = null,
  externalIcone = false,
}) => {
  if (router) {
    return (
      <Link
        className={
          className + (" btn btn-sm btn-" + (outline ? "outline-" + cor : cor))
        }
        to={alvo}
      >
        {conteudo}
      </Link>
    );
  }
  return (
    <Button
      className={className}
      type={type}
      variant={outline ? "outline-" + cor : cor}
      size="sm"
      title={title}
      href={alvo || undefined}
      target={alvo && !noBlank ? "_blank" : undefined}
      onClick={!disabled ? onClick : null}
      block={block}
      disabled={disabled}
      style={style}
    >
      {externalIcone ? (
        <>
        {conteudo}{" "}
        <Icone
        className={onInput && "align-middle pt-1"}
        style={{ fontSize: tamanhoIcone }}
        icone={isLoading ? "circle-o-notch fa-spin" : icone}
      />
        </>
      ) : (
        <>
          <Icone
        className={onInput && "align-middle pt-1"}
        style={{ fontSize: tamanhoIcone }}
        icone={isLoading ? "circle-o-notch fa-spin" : icone}
      />{" "}
      {conteudo}
        </>
      )}
      
    </Button>
  );
};

export const BotaoSalvar = ({
  className,
  type = "submit",
  title,
  onClick,
  disabled,
  temConteudo,
  isLoading,
}) => {
  return (
    <Botao
      className={className}
      type={type}
      cor="success"
      icone="save"
      title={title ? title : "Salvar"}
      conteudo={temConteudo && "Salvar"}
      onClick={!disabled ? onClick : null}
      isLoading={isLoading}
      disabled={disabled || isLoading}
    />
  );
};

export const BotaoAdicionar = ({
  className,
  type = "submit",
  title,
  onClick,
  disabled,
  temConteudo,
  conteudo,
  isLoading,
}) => {
  return (
    <Botao
      className={className}
      type={type}
      cor="success"
      icone="plus"
      title={title ? title : "Adicionar"}
      conteudo={temConteudo && "Adicionar " + (conteudo ? conteudo : "")}
      onClick={!disabled ? onClick : null}
      isLoading={isLoading}
      disabled={disabled || isLoading}
    />
  );
};

export const BotaoEditar = ({
  className,
  type = "submit",
  title,
  onClick,
  disabled,
  temConteudo,
  isLoading
}) => {
  return (
    <Botao
      className={className}
      type={type}
      icone="edit"
      title={title ? title : "Editar"}
      conteudo={temConteudo && "Editar"}
      onClick={!disabled ? onClick : null}
      disabled={disabled}
      isLoading={isLoading}
    />
  );
};

export const BotaoVoltar = ({
  className,
  type,
  title,
  onClick,
  disabled,
  temConteudo,
}) => {
  return (
    <Botao
      className={className}
      type={type}
      icone="undo"
      cor="warning"
      title={title ? title : "Voltar"}
      conteudo={temConteudo && "Voltar"}
      onClick={!disabled ? onClick : null}
      disabled={disabled}
    />
  );
};

export const BotaoAtualizar = ({
  className,
  type,
  title,
  onClick,
  disabled,
  temConteudo,
  isLoading,
}) => {
  const textoPadrao = "Atualizar ";

  return (
    <Botao
      className={className}
      type={type}
      icone="refresh"
      cor="warning"
      title={title ? textoPadrao + title : title}
      conteudo={temConteudo ? textoPadrao + title : ""}
      onClick={!disabled ? onClick : null}
      isLoading={isLoading}
      disabled={disabled || isLoading}
    />
  );
};

export const BotaoFechar = ({
  className,
  type,
  title,
  onClick,
  disabled,
  temConteudo,
}) => {
  return (
    <Botao
      className={className}
      type={type}
      icone="times"
      cor="secondary"
      title={title ? title : "Fechar"}
      conteudo={temConteudo && "Fechar"}
      onClick={!disabled ? onClick : null}
      disabled={disabled}
    />
  );
};

export const BotaoDeletar = ({
  className,
  type,
  title,
  onClick,
  disabled,
  temConteudo,
  isLoading,
}) => {
  return (
    <Botao
      className={className}
      type={type}
      icone="trash"
      cor="danger"
      title={title ? title : "Deletar"}
      conteudo={temConteudo && "Deletar"}
      onClick={!disabled ? onClick : null}
      isLoading={isLoading}
      disabled={disabled || isLoading}
    />
  );
};

export const BotaoRemover = ({
  className,
  type,
  title,
  onClick,
  disabled,
  temConteudo,
  isLoading,
}) => {
  return (
    <Botao
      className={className}
      type={type}
      icone="minus"
      cor="danger"
      title={title ? title : "Remover"}
      conteudo={temConteudo && "Remover"}
      onClick={!disabled ? onClick : null}
      isLoading={isLoading}
      disabled={disabled || isLoading}
    />
  );
};

export const BotaoGerenciar = ({
  className,
  type,
  title,
  onClick,
  disabled,
  temConteudo,
  isLoading,
  alvo,
}) => {
  return (
    <Botao
      className={className}
      type={type}
      icone="cogs"
      cor="info"
      title={title ? title : "Gerenciar"}
      conteudo={temConteudo && "Gerenciar"}
      onClick={!disabled ? onClick : null}
      isLoading={isLoading}
      disabled={disabled || isLoading}
      alvo={alvo}
    />
  );
};

export const BotaoBaseDados = ({
  className,
  type,
  title,
  onClick,
  disabled,
  temConteudo,
  alvo,
  onInput,
}) => {
  const textoPadrao = "Base de dados de ";
  return (
    <Botao
      className={className}
      type={type}
      alvo={alvo}
      icone="database"
      cor="cinza"
      title={title ? textoPadrao + title : title}
      conteudo={temConteudo ? textoPadrao + title : ""}
      onClick={!disabled ? onClick : null}
      disabled={disabled}
      onInput={onInput}
    />
  );
};

export const BotaoGrupo = ({ inline = false, column, children }) => {
  const Body = ({ children }) =>
    children.map((child, index) => (
      <div
        key={index}
        className={`d-inline-flex ${
          ++index !== children.length ? (column ? "mb-2" : "mr-2") : ""
        }`}
      >
        {child}
      </div>
    ));

  if (inline) {
    return <Body children={children} />;
  } else {
    return (
      <div>
        <Body children={children} />
      </div>
    );
  }
};

export default Botao;
