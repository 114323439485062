import React, { useState } from "react";
import { BotaoAdicionar, BotaoEditar } from "../../../../components/Botao";
import TabelaPadrao from "../../../../components/TabelaPadrao";
import { isEmpty } from "../../../../components/Util";
import ModalErgonomiaPerigoMedidaControleNecessaria from "./ModalErgonomiaPerigoMedidaControleNecessaria";

const ListaErgonomiaPerigoMedidaControleNecessaria = ({
  perigosMedidaControleNecessaria,
  buscarPerigosMedidaControleNecessaria,
  permissaoEditar,
}) => {
  return (
    <>
      <TabelaPadrao
        noOverflowAuto
        fontSize="14px"
        itens={perigosMedidaControleNecessaria}
        colunas={[
          { nome: "Código", width: "5%", text: "center" },
          { nome: "Perigo Grupo", width: "15%" },
          { nome: "Perigo" },
          { nome: "Medidas de Controle - Administrativo" },
          { nome: "Medidas de Controle - Operacional" },
          permissaoEditar && { nome: "Ações", width: "8%", text: "center" },
        ]}
      >
        {perigosMedidaControleNecessaria?.map((perigoMedidaControleNecessaria) => (
          <PerigosMedidaControleNecessaria
            key={perigoMedidaControleNecessaria.id}
            perigoMedidaControleNecessaria={perigoMedidaControleNecessaria}
            buscarPerigosMedidaControleNecessaria={buscarPerigosMedidaControleNecessaria}
            permissaoEditar={permissaoEditar}
          />
        ))}
      </TabelaPadrao>
    </>
  );
};

const PerigosMedidaControleNecessaria = ({
  perigoMedidaControleNecessaria,
  buscarPerigosMedidaControleNecessaria,
  permissaoEditar,
}) => {
  const [editando, setEditando] = useState(false);
  const [showModalCadastro, setShowModalCadastro] = useState(false);

  function selecionarCor(resultado) {
    let cor = "";
    let text = "#262626";
    switch (parseInt(resultado)) {
      case 1:
        // Azul
        cor = "#0099DD";
        break;
      case 2:
        //verde
        cor = "#04D939";
        break;
      case 3:
        //amarelo
        cor = "#EAF205";
        break;
      case 4:
        //laranja
        cor = "#F28705";
        break;
      case 5:
        //vermelho
        cor = "#BF3604";
        break;
      default:
        cor = "black";
    }

    return { backgroundColor: cor, color: text };
  }

  function selecionarResultado(resultado) {
    let resposta = "";
    switch (parseInt(resultado)) {
      case 1:
        resposta = "Insignificante";
        break;
      case 2:
        resposta = "Leve";
        break;
      case 3:
        resposta = "Moderado";
        break;
      case 4:
        resposta = "Alto";
        break;
      case 5:
        resposta = "Grave";
        break;
      default:
        resposta = " - ";
    }

    return resposta;
  }
  
  return (
    <>
      <tr key={perigoMedidaControleNecessaria.id}>
        <td className="text-center align-middle">{perigoMedidaControleNecessaria.codigo}</td>
        <td className="align-middle">{perigoMedidaControleNecessaria.grupo}</td>
        <td className="align-middle">{perigoMedidaControleNecessaria.definicao}</td>
        <td className="align-middle">
          {isEmpty(perigoMedidaControleNecessaria.medidasControleNecessarias) ? (
            <b>Nenhuma medida de controle necessária vinculada.</b>
          ) : (
            perigoMedidaControleNecessaria.medidasControleNecessarias.map((medidaControleNecessarias) => (
              <>
                <ul>
                  <li>
                      {medidaControleNecessarias.definicao}
                      <span
                        className="badge ms-1"
                        style={selecionarCor(medidaControleNecessarias.resultado_matematica)}
                      >
                        {selecionarResultado(medidaControleNecessarias.resultado_matematica)}
                      </span>
                  </li>
                </ul>
              </>
            ))
          )}
        </td>
        <td className="align-middle">
        {isEmpty(perigoMedidaControleNecessaria.medidasControleNecessariasOperacional) ? (
            <b>Nenhuma medida de controle necessária vinculada.</b>
          ) : (
            perigoMedidaControleNecessaria.medidasControleNecessariasOperacional.map((medidaControleNecessarias) => (
              <>
                <ul>
                  <li>
                      {medidaControleNecessarias.definicao}
                      <span
                        className="badge ms-1"
                        style={selecionarCor(medidaControleNecessarias.resultado_matematica)}
                      >
                        {selecionarResultado(medidaControleNecessarias.resultado_matematica)}
                      </span>
                  </li>
                </ul>
              </>
            ))
          )}
        </td>
        {permissaoEditar && (
          <>
            <td className="text-center align-middle">
              {(isEmpty(perigoMedidaControleNecessaria.medidasControleNecessarias) && isEmpty(perigoMedidaControleNecessaria.medidasControleNecessariasOperacional)) ? (
                <BotaoAdicionar
                  onClick={() => setShowModalCadastro(true)}
                  disabled={perigoMedidaControleNecessaria.id === "59" && true}
                />
              ) : (
                <BotaoEditar
                  onClick={() => setEditando(true)}
                  disabled={perigoMedidaControleNecessaria.id === "59" && true}
                />
              )}
            </td>
          </>
        )}
      </tr>
      {editando && (
        <ModalErgonomiaPerigoMedidaControleNecessaria
          show={editando}
          setShow={setEditando}
          tituloSpan={"Ergonomia"}
          titulo={"Editar vinculos Perigo x Risco"}
          corTitulo="primary"
          perigoMedidaControleNecessaria={perigoMedidaControleNecessaria}
          buscarPerigosMedidaControleNecessaria={buscarPerigosMedidaControleNecessaria}
        />
      )}
      {showModalCadastro && (
        <ModalErgonomiaPerigoMedidaControleNecessaria
          show={showModalCadastro}
          setShow={setShowModalCadastro}
          titulo={"Cadastrar vinculos Perigo x Risco"}
          corTitulo="success"
          perigoMedidaControleNecessaria={perigoMedidaControleNecessaria}
          buscarPerigosMedidaControleNecessaria={buscarPerigosMedidaControleNecessaria}
        />
      )}
    </>
  );
};

export default ListaErgonomiaPerigoMedidaControleNecessaria;
