import React, { useEffect, useState } from "react";

import { filtrar } from "../../../../components/Util";
import api from "../../../../service/api";
import TemplateBaseDadosNavtabs from "../../TemplateBaseDadosNavtabs";
import ListaMedidaControleNecessaria from "./ListaMedidaControleNecessaria";
import ModalMedidaControleNecessaria from "./ModalMedidaControleNecessaria";

const MedidaControleNecessaria = ({ permissaoEditar }) => {
  const [medidasControleNecessaria, setMedidasControleNecessaria] = useState([]);
  const [apreMedidasControleNecessaria, setApreMedidasControleNecessaria] = useState([]);

  const [filtro, setFiltro] = useState("");
  const [showModalCadastro, setShowModalCadastro] = useState(false);
  const item = "Medidas de Controle Necessária";

  useEffect(() => {
    buscarMedidasControleNecessaria();
    buscarApreMedidaControleNecessaria();
  }, []);

  const medidasControleNecessariaFiltradas = medidasControleNecessaria.filter(
    (medidaControle) => filtrar(medidaControle.id, filtro) || filtrar(medidaControle.definicao, filtro)
  );

  const handleFilter = (newFilter) => {
    setFiltro(newFilter);
  };

  async function buscarMedidasControleNecessaria() {
    await api
      .get("/baseDados/ergonomiaBaseDados/buscarMedidasControleNecessaria")
      .then((resposta) => {
        setMedidasControleNecessaria(resposta.data);
      })
      .catch((e) => console.log(e));
  }

  async function buscarApreMedidaControleNecessaria() {
    await api
      .get("/baseDados/ergonomiaBaseDados/buscarApreMedidaControleNecessaria")
      .then((resposta) => {
        setApreMedidasControleNecessaria(resposta.data);
      })
      .catch((e) => console.log(e));
  }

  return (
    <>
      <TemplateBaseDadosNavtabs
        item={item}
        itensFiltrados={medidasControleNecessariaFiltradas}
        totalItens={medidasControleNecessariaFiltradas.length}
        handleFilter={(e) => {
          handleFilter(e.target.value);
        }}
        filtro={filtro}
        isModal
        handleClick={() => permissaoEditar && setShowModalCadastro(true)}
      >
        {(currentItems) => (
          <ListaMedidaControleNecessaria
            medidasControleNecessaria={currentItems}
            buscarMedidasControleNecessaria={buscarMedidasControleNecessaria}
            apreMedidasControleNecessaria={apreMedidasControleNecessaria}
            permissaoEditar={permissaoEditar}
          />
        )}
      </TemplateBaseDadosNavtabs>
      {showModalCadastro && (
        <ModalMedidaControleNecessaria
          show={showModalCadastro}
          setShow={setShowModalCadastro}
          titulo="Cadastrar Medida de Controle Necessária"
          corTitulo="success"
          buscarMedidasControleNecessaria={buscarMedidasControleNecessaria}
          novaMedidaControleNecessaria={filtro}
          apreMedidasControleNecessaria={apreMedidasControleNecessaria}
        />
      )}
    </>
  );
};

export default MedidaControleNecessaria;
