import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import api from "../service/api";
import LogoCliente from "./LogoCliente";
import { isEmpty } from "./Util";

const LogoClienteEnsaioVedacao = ({ clienteId, clienteEstabelecimentoId }) => {
  const [clienteNome, setClienteNome] = useState();
  const [estabelecimentoCliente, setEstabelecimentoCliente] = useState();
  const [foto, setFoto] = useState()

  useEffect(() => {
    async function buscarClientes() {
      await api.get(`/clientes/${clienteId}`).then((resposta) => {
        setClienteNome(resposta.data.nome_fantasia)
        setFoto(resposta.data.foto)
      })
    }
    buscarClientes(clienteId);

    async function buscarEstabelecimento() {
      await api
        .get(`/clientes/obterClienteEstabelecimento/${clienteEstabelecimentoId}`)
        .then((resposta) => setEstabelecimentoCliente(resposta.data?.estabelecimento?.nome_generico));
    }

    !isEmpty(clienteEstabelecimentoId) && buscarEstabelecimento(clienteEstabelecimentoId);
  }, [clienteId, clienteEstabelecimentoId]);

  return (
    <div style={{ border: "1px solid black", borderRadius: "15px" }} className="container-flex ms-2 me-2 bg-white">
      <div className="row d-flex align-items-center">
        <div className="col-2 p-2 ms-2 mt-1 mb-1">
          <Link to={`/gro/${clienteId}/pgr`}>
            <LogoCliente nomeFoto={foto} maxWidth="135px" maxHeight="40px" />
          </Link>
        </div>
        <div className="col-4 ms-2">
          <h6>
            <strong>Cliente:</strong> {clienteNome}
          </h6>
        </div>
        {!isEmpty(estabelecimentoCliente) && (
        <>
        <div className="col">
          <h6>
            <strong>Estabelecimento:</strong> {estabelecimentoCliente}
          </h6>
        </div>
        </>
        )}
      </div>
    </div>
  );
};

export default LogoClienteEnsaioVedacao;
