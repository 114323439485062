import React from "react";
import { useState } from "react";
import { useEffect } from "react";

import Botao from "../../../components/Botao";
import api from "../../../service/api";
import { isEmpty } from "../../../components/Util";
import Loader from "../../../components/Loader";
import Ambientes from "./Ambientes";

const FormasRiscos = ({ setor }) => {
  const [formasRiscos, setFormasRiscos] = useState([]);
  const [ambientes, setAmbientes] = useState([]);
  const [formaRiscoAtiva, setFormaRiscoAtivo] = useState({});
  const [carregandoAmbientes, setCarregandoAmbientes] = useState(true);

  const [carregando, setCarregando] = useState(false);

  const [medidasOrganizacionaisTrabalho, setMedidasOrganizacionaisTrabalho] = useState([]);

  useEffect(() => {
    carregarRiscos(setor.setor_id, setor.ppra_id);
    carregarAmbientes(setor.setor_id, setor.ppra_id, "1", "1", true);
    setFormaRiscoAtivo({
      id: "1",
      nome: "Físico",
      definicao: "Ruído",
      risco_id: "1",
    });

    carregarMedidas();
  }, [setor]);

  async function carregarAmbientes(setorId, ppraId, riscoId, formaRiscoId, isCarregar = false) {
    setCarregandoAmbientes(isCarregar);
    await api
      .get("/ppra/quadros/obterAmbientesPorRisco", {
        params: {
          setor_id: setorId,
          ppra_id: ppraId,
          risco_id: riscoId,
          forma_risco_id: formaRiscoId,
        },
      })
      .then((resposta) => {
        setAmbientes(resposta.data);
        setCarregandoAmbientes(false);
      })
      .catch((e) => {
        console.log(e);
      });
  }

  async function carregarMedidas() {
    await api
      .get("/baseDados/MedidaOrganizacionalTrabalho")
      .then((resposta) => setMedidasOrganizacionaisTrabalho(resposta.data))
      .catch((erro) => console.log(erro));
  }

  async function carregarRiscos(setorId, ppraId) {
    setCarregando(true);
    await api
      .get("/ppra/quadros/obterRiscos", {
        params: { ppra_id: ppraId, setor_id: setorId },
      })
      .then((resposta) => {
        setFormasRiscos(resposta.data);
        setCarregando(false);
      })
      .catch((e) => {
        setCarregando(false);
        console.log(e);
      });
  }

  function handleClick(formaRisco) {
    setFormaRiscoAtivo(formaRisco);
    if (formaRisco.id !== formaRiscoAtiva.id) {
      setCarregandoAmbientes(true);
      carregarAmbientes(setor.setor_id, setor.ppra_id, formaRisco.risco_id, formaRisco.id, true);
    }
  }

  if (carregando) {
    return <Loader />;
  }

  return (
    <>
      <div className="row mb-2">
        <div className="col">
          <div className="card">
            <div className="card-header">
              <div className="row">
                <div className="col">
                  Setor{" "}
                  <div className="badge bg-info">
                    {setor.numero}) {setor.nome}
                  </div>
                </div>
                <div className="col-1">
                  <Botao
                    cor="secondary"
                    alvo={process.env.REACT_APP_API_URL + `/gro/pgr/pdf/quadros/gerarRevisao/${setor.ppra_id}/${setor.setor_id}`}
                    title="Gerar PDF do setor"
                    icone="print"
                    conteudo="PDF"
                  />
                </div>
              </div>
            </div>
            <div className="p-3">
              {formasRiscos.map((formaRisco) => (
                <Botao
                  className="m-1"
                  key={formaRisco.id}
                  cor={formaRiscoAtiva.id === formaRisco.id ? "info" : "primary"}
                  conteudo={formaRisco.definicao}
                  onClick={() => handleClick(formaRisco)}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col">
          {carregandoAmbientes ? (
            <Loader />
          ) : isEmpty(ambientes) ? (
            "Não exitem ambientes para serem exibidos!"
          ) : (
            <FormaRisco
              setor={setor}
              formaRisco={
                formaRiscoAtiva
                  ? formaRiscoAtiva
                  : {
                      id: "1",
                      nome: "Físico",
                      definicao: "Ruído",
                      risco_id: "1",
                    }
              }
              ambientes={ambientes}
              carregarAmbientes={carregarAmbientes}
              medidasOrganizacionaisTrabalho={medidasOrganizacionaisTrabalho}
            />
          )}
        </div>
      </div>
    </>
  );
};

const FormaRisco = ({ setor, formaRisco, ambientes, carregarAmbientes, ...other }) => {
  const [carregando, setCarregando] = useState(false);
  const matematicaCadastrada = isEmpty(ambientes.find((find) => find.quantidade_matematicas > 0));

  function cadastrarMatematicaTodosAmbientes(ambientes) {
    setCarregando(true);
    api.post(`gro/pgr/matematica/matematica/inserirMatematicaTodosAmbientes/${formaRisco.id}`, ambientes).then(() => {
      carregarAmbientes(setor.setor_id, setor.ppra_id, formaRisco.risco_id, formaRisco.id, true);
      setCarregando(false);
    });
  }

  function atualizarMatematicaSetor(setorId, ppraId, formaRiscoId) {
    setCarregando(true);
    api.get(`gro/pgr/matematica/matematica/atualizarMatematicaSetor/${setorId}/${ppraId}/${formaRiscoId}`).then(() => {
      carregarAmbientes(setor.setor_id, setor.ppra_id, formaRisco.risco_id, formaRisco.id, true);
      setCarregando(false);
    });
  }

  function deletarMatematicaTodosAmbientes(setorId, ppraId, formaRiscoId) {
    setCarregando(true);
    api.delete(`gro/pgr/matematica/matematica/deletarMatematicaSetor/${setorId}/${ppraId}/${formaRiscoId}`).then(() => {
      carregarAmbientes(setor.setor_id, setor.ppra_id, formaRisco.risco_id, formaRisco.id, true);
      setCarregando(false);
    });
  }
  return (
    <div className="card">
      <div className="card-header">
        <div className="row">
          <div className="col-auto">
            Risco
            <div className="badge bg-info ms-2">
              {formaRisco.nome} - {formaRisco.definicao}
            </div>
          </div>

          <div className="col-auto">
            <Botao
              cor="success"
              icone="plus"
              conteudo="Cadastrar"
              title="Cadastrar todas as matemáticas faltantes de todos os ambientes do risco selecionado"
              onClick={() => cadastrarMatematicaTodosAmbientes(ambientes)}
            />
            {!matematicaCadastrada && (
              <>
                <Botao
                  className="ms-2"
                  cor="warning"
                  icone="refresh"
                  conteudo="Atualizar matemáticas"
                  title="Atuliza os dados de todas matemáticas do setor, verificando probabilidade e severidade cadastradas"
                  onClick={() => atualizarMatematicaSetor(setor.setor_id, setor.ppra_id, formaRisco.id)}
                />
                <Botao
                  className="ms-2"
                  cor="danger"
                  icone="trash"
                  title="Deletar todas as matemáticas do ambiente e risco selecionado"
                  conteudo="Deletar matemáticas"
                  onClick={() => deletarMatematicaTodosAmbientes(setor.setor_id, setor.ppra_id, formaRisco.id)}
                />
              </>
            )}
          </div>
        </div>
        {/* Risco{" "}
        <div>
          <Botao/>
        </div> */}
      </div>
      <div className="card-body">
        {carregando ? (
          <Loader />
        ) : (
          ambientes?.map((ambiente, indexAmbiente) => (
            <Ambientes
              key={ambiente.local_quadro_id}
              ambiente={ambiente}
              indexAmbiente={indexAmbiente}
              formaRisco={formaRisco}
              carregarAmbientes={carregarAmbientes}
              setor={setor}
              {...other}
            />
          ))
        )}
      </div>
    </div>
  );
};

export default FormasRiscos;
